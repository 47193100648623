var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "form",
                {
                  attrs: { "data-vv-scope": "send-email" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.sendEmailToPicklrs($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _c("h2", [_vm._v("Send Email")]),
                      _c("br"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.subject,
                            expression: "subject"
                          }
                        ],
                        class: {
                          input: true,
                          "is-danger": _vm.errors.has("send-email.subject")
                        },
                        attrs: {
                          placeholder: "Enter Email Subject",
                          type: "text"
                        },
                        domProps: { value: _vm.subject },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.subject = $event.target.value
                          }
                        }
                      }),
                      _c("VueEditor", {
                        attrs: { "use-custom-image-handler": "" },
                        on: { "image-added": _vm.handleImageAdded },
                        model: {
                          value: _vm.content,
                          callback: function($$v) {
                            _vm.content = $$v
                          },
                          expression: "content"
                        }
                      }),
                      _c("button", {
                        ref: "popupCloseButton",
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          "aria-label": "Close"
                        }
                      })
                    ],
                    1
                  ),
                  _c("input", {
                    staticClass: "btn-lg-green",
                    attrs: { type: "submit", disabled: _vm.submittingForm },
                    domProps: { value: _vm.submitButtonText }
                  })
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }