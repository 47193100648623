<template>
  <div>
  <div class="send-msg-check" v-if="userRole=='super-admin'">
      <input type="checkbox" v-bind:value="picklr.id" v-bind:id="picklr.id" :checked="isUserChecked(picklr.id)"  @change="checkPicklr($event)">
   </div>
  <div class="green-border-box picklr-item white-bg">

    <router-link :to="'/'+userUrl+'/picklrs/' + picklr.id">
      <div class="col-xs-12 col-sm-5 profile-details">
        <div class="green-border-box profile-photo">
          <img
            v-square-img
            :src="picklr.image + '?w=150&q=50'?picklr.image:imageLoadOnError"
            @error="imageLoadOnError"
          >
        </div>
        <div class="afterImage">
          <div
            class="profile-name"
            :title="picklr.name"
            v-autosize="{ target: 22, min: 16 }"
          >{{picklr.name}}</div>
          <div
            style="user-select:all"
            class="phone-number"
            :title="picklr.mobile_number"
            v-autosize="{ target: 16, min: 16 }"
          >{{picklr.mobile_number | phone}}</div>
          <div class="rating">
            <img v-for="n in picklr.rating" :key="n" src="/img/Alerts/picklerating_icon.png">
          </div>
          <div v-if="picklr.last_active_device" style="display:inline;">
            <span
              class="app-gps-status-using-no"
              title="GPS Status - No"
              v-autosize="{ target: 16, min: 16 }"
              v-if="picklr.last_active_device.app_gps_status === 'no'"
            > &nbsp;
            </span>

            <span
              class="app-gps-status-using-app"
              title="GPS Status - While Using App"
              v-autosize="{ target: 16, min: 16 }"
              v-if="picklr.last_active_device.app_gps_status === 'while_using_app'"
            > &nbsp;
            </span>

            <span
              class="app-gps-status-always-allowed"
              title="GPS Status - Always Allowed"
              v-autosize="{ target: 16, min: 16 }"
              v-if="picklr.last_active_device.app_gps_status === 'alway_allowed'"
            > &nbsp;
            </span>
          </div>
          <div v-if="picklr.last_active_device" style="display:inline;">
            <span
              class="app-notification-no"
              title="GPS Notification - No"
              v-autosize="{ target: 16, min: 16 }"
              v-if="picklr.last_active_device.app_notification === 'no'"
            > &nbsp;
            </span>

            <span
              class="app-notification-yes"
              title="GPS Notification - Yes"
              v-autosize="{ target: 16, min: 16 }"
              v-if="picklr.last_active_device.app_notification === 'yes'"
            > &nbsp;
            </span>
          </div>
          <span v-if="picklr.last_active_device"
              class="app-version"
              title="App Version"
              v-autosize="{ target: 16, min: 16 }"
            >
              {{picklr.last_active_device.app_version}}
          </span>
              <div>
                <a class="address">
                  <div>
                    <div v-for="(zip_code, index) in picklr.zip_codes" :key="zip_code.id" >{{zip_code.formatted_address}}<div v-if="index < (picklr.zip_codes.length - 1)"></div>
                    </div>
                  </div>
                </a>
              </div>
        </div>
      </div>
    </router-link>
    <div class="col-xs-12 col-sm-2 date-time">
      {{picklr.date}}
      <br class="hidden-xs">
      <span class="hidden-sm hidden-md hidden-lg">,</span>
      {{picklr.time}}
    </div>
    <div class="col-xs-12 col-sm-5 nopadd">
      <div class="part part-white">
        <h4>{{picklr.completed_pickls_count}}</h4>Completed
      </div>
      <div
        class="part part-white"
        :class="{'due-alert': picklr.current_credits >= $store.state.settings.credit_payment_threshold}"
      >
        <!-- Have currency formatting -->
        <h4>${{picklr.current_credits}}</h4>
        <span>Due</span>
      </div>
      <div class="part part-grey">
        <h4 v-if="picklr.credits_paid_sum">${{picklr.credits_paid_sum}}</h4>
        <h4 v-else>$0</h4>Paid
      </div>
    </div>
  </div>

</div>

</template>

<script>

export default {
  name: 'PicklrItem',
  data () {
    return {
      userRole: 'staff',
      userUrl: 'staff'
    }
  },
  props: {
    picklr: {},
    selectedPicklrs: {},
    selectAllPicklrs: false
  },
  methods: {
    imageLoadOnError () {
      this.picklr.image = '/img/pickl-logo.png'
    },
    checkPicklr: function (e) {
      if (e.target.checked) {
        this.$emit('updateCheckedUsers', parseInt(e.target.value), 'added')
      } else {
        this.$emit('updateCheckedUsers', parseInt(e.target.value), 'removed')
      }
    },
    isUserChecked (picklrId) {
      if (this.selectAllPicklrs) {
        return true
      }
      if (!this.selectAllPicklrs) {
        return false
      }
      if (this.selectedPicklrs.indexOf(picklrId) === -1) {
        return false
      } else {
        return true
      }
    }
  },
  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  }
}
</script>

<style scoped>
.picklr-item {
  float: left;
  width: 98%;
  margin: 5px;
  overflow: hidden;
  margin-left:3%;
}

.profile-details {
  padding: 15px;
}

.profile-details .profile-photo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
  vertical-align: top;
  display: inline-block;
  position: relative;
  margin-top: 11px;
}

.profile-details .profile-photo img {
  position: absolute;
}

.profile-details .afterImage {
  width: calc(100% - 85px);
  display: inline-block;
  padding: 10px 10px 10px 0;
}

.profile-details .profile-name {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  overflow: hidden;
}

.profile-details .rating img {
  width: 13px;
  height: 12px;
  margin-right: 3px;
}

.part {
  display: inline-block;
  width: 33.333%;
  text-align: center;
  padding: 32px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
}

.part-white {
  background: #fff;
  border-left: 1px solid #ededed;
}

.part-grey {
  background: #f7f8f9;
}

.part h4 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 23px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 11px;
}

.due-alert h4,
.due-alert span {
  color: #e34917;
}

.due-alert span {
  border-radius: 360px;
  border: 1px solid #e34917;
  padding: 0 18px;
  line-height: 20px;
  display: inline-block;
}

.date-time {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  padding: 31px 0;
}

.contact-number {
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}

.phone-number,
.phone-number:hover,
.phone-number:focus,
.phone-number:active {
  padding-left: 20px;
  background-image: url("/img/Picklr/call_icon.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.app-gps-status-using-app,
.app-gps-status-using-app:hover,
.app-gps-status-using-app:focus,
.app-gps-status-using-app:active {
  padding-left: 20px;
  background-image: url("/img/Picklr/GPS_green_16x16.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.app-gps-status-using-no,
.app-gps-status-using-no:hover,
.app-gps-status-using-no:focus,
.app-gps-status-using-no:active {
  padding-left: 20px;
  background-image: url("/img/Picklr/GPS_gray_16x16.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.app-gps-status-always-allowed,
.app-gps-status-always-allowed:hover,
.app-gps-status-always-allowed:focus,
.app-gps-status-always-allowed:active {
  padding-left: 20px;
  background-image: url("/img/Picklr/GPS_purple_16x16.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.app-version,
.app-version:hover,
.app-version:focus,
.app-version:active {
  padding-left: 20px;
  background-image: url("/img/Picklr/AppVersion_gray_16x16.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.app-notification-yes,
.app-notification-yes:hover,
.app-notification-yes:focus,
.app-notification-yes:active {
  padding-left: 20px;
  background-image: url("/img/Picklr/Notification_purple_16x16.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.app-notification-no,
.app-notification-no:hover,
.app-notification-no:focus,
.app-notification-no:active {
  padding-left: 20px;
  background-image: url("/img/Picklr/Notification_gray_16x16.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .part {
    padding: 15px 0;
    border-top: 1px solid #ededed;
  }

  .date-time {
    line-height: 20px;
    margin-bottom: 10px;
    padding: 0;
  }
}
.send-msg-check input[type="checkbox"] {
  margin-bottom: -75px;
}
.address {
  padding-left: 20px;
  background-image: url("/img/Picklr/address_icon.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 14px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}
</style>
