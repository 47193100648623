<template>
  <div>
    <div class="row">
      <div class="col-xs-12 nopadd-right sort-control">
        <div class="col-md-12" style="border: solid 1px #12db6d; background-color: rgb(244 244 244 / 61%);margin-bottom: 5px;padding-bottom:5px;">
          <h4>Address & Personal Filters <button @click="togglePersonalFilters" v-html="showPersonalDetailsFilter? '-':'+'" style="float:right;background-color: #782b55;color:white;border-radius: 5px;"></button></h4>
          <div class="filters" v-show="showPersonalDetailsFilter">
            <input class="btn-select" type="text" placeholder="Name" v-model="name" />
            <select v-model="countryId" @change="getStatesByCountry">
              <option value="">Select Country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{country.name}}</option>
            </select>
            <select v-model="stateName">
              <option value="">Select State</option>
              <option v-for="state in states" :key="state.id" :value="state.name">{{state.name}}</option>
            </select>
            <input class="btn-select" type="text" placeholder="City" v-model="city" />
            <input class="btn-select" type="text" placeholder="Mobile Number" v-model="mobileNumber" />
            <select v-model="status">
              <option value>All</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="BLOCKED">Blocked</option>
              <option value="REPORTED">Reported</option>
            </select>
            <input class="btn-select" type="text" placeholder="Zipcode" v-model="zipCode" />
            <select v-model="sort">
              <option value="created_at-DESC" selected>Sign-up &#8595;</option>
              <option value="created_at-ASC">Sign-up &#8593;</option>
              <option value="name-ASC">A-Z</option>
              <option value="name-DESC">Z-A</option>
              <option value="current_credits-ASC">Due &#8593;</option>
              <option value="current_credits-DESC">Due &#8595;</option>
              <option value="completed_pickls_count-ASC">Pickls &#8593;</option>
              <option value="completed_pickls_count-DESC">Pickls &#8595;</option>
            </select>
          </div>
        </div>
        <div class="col-md-12" style="border: solid 1px #12db6d;border-radius: 5px; background-color: rgb(244 244 244 / 61%);margin-bottom: 5px;padding-bottom:5px;">
          <h4>Demographics Filters <button @click="toggleDemographicFilters" v-html="showDemographicFilters? '-':'+'" style="float:right;background-color: #782b55;color:white;border-radius: 5px;"></button></h4>

          <div class="filters" v-show="showDemographicFilters">
            <select v-model="gender">
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Non-Binary">Non-Binary</option>
            </select>
            <select v-model="age">
              <option value="">Age</option>
              <option value="13-18">13-18</option>
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value="46-55">46-55</option>
              <option value="56-65">56-65</option>
              <option value="66-75">66-75</option>
              <option value="76-85">76-85</option>
              <option value="86-95">86-95</option>
            </select>
            <select v-model="children">
              <option value="">Children</option>
              <option value="1 child">1 child</option>
              <option value="2 children">2 Children</option>
              <option value="3 or more children">3 or more children</option>
              <option value="No children">No children</option>
            </select>
            <select v-model="pets">
              <option value="">Pets</option>
              <option value="Dog">Dog</option>
              <option value="Cat">Cat</option>
              <option value="Fish">Fish</option>
              <option value="Bird/Small Animal">Bird/Small Animal</option>
              <option value="Reptile/Other">Reptile/Other</option>
              <option value="I don't have a pet">I don't have a pet</option>
            </select>
            <select v-model="ethnic_background">
              <option value="">Enthnic Background</option>
              <option v-for="eb in enthicBackgroundList" :value="eb" :key="eb">{{ eb }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-12" style="border: solid 1px #12db6d; background-color: rgb(244 244 244 / 61%);margin-bottom: 5px;padding-bottom:5px;">
          <h4>Actions <button @click="toggleActions" v-html="showActions? '-':'+'" style="float:right;background-color: #782b55;color:white;border-radius: 5px;"></button></h4>
          <div class="filters" v-show="showActions" style="padding-bottom: 10px;">
            <button
            class="btn-positive btn-send-message"
            data-toggle="modal"
            data-target="#send-message-popup"
            v-if="userRole=='super-admin'"
          >Send Message</button>
          <button
            class="btn-positive btn-send-message"
            data-toggle="modal"
            data-target="#send-notification-popup"
            v-if="userRole=='super-admin'"
          >Send Notification</button>
          <button
            class="btn-positive btn-send-message"
            data-toggle="modal"
            data-target="#send-email-popup"
            v-if="userRole=='super-admin'"
          >Send Email</button>
          <button
            class="btn-positive btn-send-message"
            data-toggle="modal"
            data-target="#clear-blocked-users-popup"
            v-if="userRole=='super-admin'"
          >Reset Message Blocked Users List</button>
          <button
            class="btn-positive btn-send-message"
            data-toggle="modal"
            data-target="#clear-notification-blocked-users-popup"
            v-if="userRole=='super-admin'"
          >Reset Notification Blocked Users List</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="checkedUsersArr.length > 0"> -->
    <SendMessagePopup
      popupId="send-message-popup"
      :checkedUsersList="checkedUsersArr"
      :excludeResponders="false"
      :picklId="null"
      @messageSent="messageSent"
      :selectAllPicklrs="selectAllPicklrs"
      :picklrFilters="picklrFilters"
    />
    <SendCustomNotificationPopup
      popupId="send-notification-popup"
      :checkedUsersList="checkedUsersArr"
      :excludeResponders="false"
      :picklId="null"
      @notificationSent="messageSent"
      :selectAllPicklrs="selectAllPicklrs"
      :picklrFilters="picklrFilters"
    />

    <SendEmailPopup
      popupId="send-email-popup"
      :checkedUsersList="checkedUsersArr"
      :excludeResponders="false"
      :picklId="null"
      @emailSent="messageSent"
      :selectAllPicklrs="selectAllPicklrs"
      :picklrFilters="picklrFilters"
    />

    <ClearBlockedUsersPopup
      popupId="clear-blocked-users-popup"
    />
    <ClearNotificationBlockedUsersPopup
      popupId="clear-notification-blocked-users-popup"
    />
    <!-- </div> -->
    <div class="send-msg-check" v-if="userRole=='super-admin'">
      <input type="checkbox" :checked="selectAllPicklrs"  @change="checkAllPicklrs($event)"><label>Select All Picklrs</label>
      <span style="float:right;">Total: {{ totalPicklrs }}</span>
    </div>
    <div v-if="picklrs.length > 0">
      <PicklrItem
        v-for="picklr in picklrs"
        :key="picklr.id"
        :picklr="picklr"
        :selectedPicklrs="checkedUsersArr"
        @updateCheckedUsers="checkedUsersList"
        :selectAllPicklrs="selectAllPicklrs"
      />
    </div>
    <h2
      v-if="picklrs.length === 0 && !loadingPicklrs"
      style="text-align:center;"
    >No Picklr Found in system!</h2>
    <h2 v-if="loadingPicklrs" style="text-align:center;">Loading Picklrs...</h2>
    <div class="text-center" v-if="hasMorePicklrs && !loadingPicklrs" @click="getPicklrs">
      <button class="btn-positive">Load more</button>
    </div>
  </div>
</template>

<script>

import PicklrItem from '@/components/SAdmin/PicklrItem.vue'
import SendMessagePopup from '@/components/SAdmin/SendMessagePopup.vue'
import ClearBlockedUsersPopup from '@/components/SAdmin/ClearBlockedUsersPopup.vue'
import ClearNotificationBlockedUsersPopup from '@/components/SAdmin/ClearNotificationBlockedUsersPopup.vue'

import SendCustomNotificationPopup from '@/components/SAdmin/SendCustomNotificationPopup.vue'
import SendEmailPopup from '@/components/SAdmin/SendEmailPopup.vue'

export default {
  name: 'Picklr',
  data () {
    return {
      enthicBackgroundList: ['Multiracial/Mixed', 'Hispanic/LATAM', 'African American/Black', 'White', 'Asian or Pacific Islander', 'Middle Eastern', 'Native American/Other'],
      showPersonalDetailsFilter: true,
      gender: '',
      age: '',
      children: '',
      ethnic_background: '',
      pets: '',
      cosmetics: '',
      showDemographicFilters: false,
      showActions: false,
      loadingPicklrs: true,
      hasMorePicklrs: false,
      city: '',
      zipCode: '',
      stateName: '',
      status: this.$route.query['status'] || 'ACTIVE',
      rowsLimitPerRequest: 100,
      rowsOffset: 0,
      picklrs: [],
      sort: this.$route.query['sortBy'] || 'created_at-DESC',
      name: '',
      mobileNumber: '',
      checkedUsersArr: [],
      userRole: 'super-admin',
      states: [],
      selectAllPicklrs: false,
      lat: this.$route.query['lat'] || '',
      lng: this.$route.query['lng'] || '',
      picklrFilters: {},
      countries: [],
      countryId: '',
      totalPicklrs: 0
    }
  },
  components: {
    PicklrItem,
    SendMessagePopup,
    ClearBlockedUsersPopup,
    SendCustomNotificationPopup,
    ClearNotificationBlockedUsersPopup,
    SendEmailPopup
  },

  created () {
    this.$store.dispatch('getAppSettingsKeyValuePair')
  },
  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    this.getPicklrs()
    this.getCountries()
  },
  methods: {
    togglePersonalFilters () {
      this.showPersonalDetailsFilter = !this.showPersonalDetailsFilter
      console.log('here we are')
    },
    toggleDemographicFilters () {
      this.showDemographicFilters = !this.showDemographicFilters
    },
    toggleActions () {
      this.showActions = !this.showActions
    },
    getCountries () {
      this.$http.get('countries?service_enabled=true').then((response) => {
        this.countries = response.body.data
      })
    },
    checkAllPicklrs (e) {
      if (e.target.checked) {
        this.selectAllPicklrs = true
      } else {
        this.selectAllPicklrs = false
      }
    },

    getStatesByCountry () {
      this.picklrs = []
      this.rowsOffset = 0
      this.getPicklrs()
      if (this.countryId !== '') {
        this.$http.get('states?country_id=' + this.countryId).then((response) => {
          this.states = response.body.data
        })
      } else {
        this.states = []
      }
    },

    messageSent () {
      Object.assign(this.$data, (this.$options.data).apply(this))
      this.reFilter()
      this.checkedUsersArr = []
      this.getCountries()
    },

    checkedUsersList (selectedUsers, status) {
      const found = this.checkedUsersArr.indexOf(selectedUsers)
      if (status === 'added') {
        this.checkedUsersArr.push(selectedUsers)
      } else {
        this.checkedUsersArr.splice(found, 1)
      }
    },
    getPicklrs () {
      this.loadingPicklrs = true
      let q = {
        name: this.name,
        status: this.status,
        city: this.city,
        countryId: this.countryId,
        stateName: this.stateName,
        zipCode: this.zipCode,
        mobile_number: this.mobileNumber,
        limit: this.rowsLimitPerRequest,
        offset: this.rowsOffset,
        sort: this.sort,
        lat: this.lat,
        lng: this.lng,
        gender: this.gender,
        age: this.age,
        pets: this.pets,
        children: this.children,
        ethnic_background: this.ethnic_background
      }
      this.picklrFilters = q
      this.$http
        .get('picklrs', { params: q })
        .then(
          function (response) {
            if (this.rowsLimitPerRequest === q.limit && this.rowsOffset === q.offset) {
              this.rowsOffset += this.rowsLimitPerRequest
              this.picklrs.push(...response.body.data)
              this.selectAllPicklrs = false
              this.totalPicklrs = response.body.count
              if (this.picklrs.length === response.body.count) {
                this.hasMorePicklrs = false
              } else {
                this.hasMorePicklrs = true
              }
              this.loadingPicklrs = false
            }
          }
        )
    },
    reFilter () {
      this.picklrs = []
      this.rowsOffset = 0
      this.getPicklrs()
      this.$router.push({ query: { sortBy: this.sort, status: this.status, lat: this.lat, lng: this.lng } })
    }
  },
  watch: {
    sort () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    name () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    city () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    zipCode () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    status () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    stateName () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    mobileNumber () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    age () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    gender () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    ethnic_background () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    pets () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    children () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    }
  }
}
</script>

<style scoped>
.sort-control {
  margin-bottom: 8px;
}

.sort-control .btn-select,
.sort-control select {
  margin-bottom: 7px;
}

div.dropdown .btn-select + ul.dropdown-menu {
  top: calc(100% - 7px);
}

.filters {
  display: inline-block;
  width: calc(100% - 66px);
  vertical-align: text-top;
}

@media only screen and (max-width: 767px) {
  .sort-control {
    text-align: left;
    padding-left: 15px;
  }
}
.btn-send-message {
  margin-left: 3%;
}
</style>
