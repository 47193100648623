<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <form data-vv-scope="send-email" @submit.prevent="sendEmailToPicklrs">
            <div class="modal-header">
              <h2>Send Email</h2><br/>
              <input placeholder="Enter Email Subject" type="text" v-model="subject"
              :class="{ 'input': true, 'is-danger': errors.has('send-email.subject') }" />

              <VueEditor
              use-custom-image-handler @image-added="handleImageAdded"
                v-model="content" />
              <button
                ref="popupCloseButton"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <input
              type="submit"
              :value="submitButtonText"
              class="btn-lg-green"
              :disabled="submittingForm"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },
  props: ['popupId', 'checkedUsersList', 'picklrFilters', 'selectAllPicklrs'],
  data () {
    return {
      subject: '',
      content: '',
      submittingForm: false,
      submitButtonText: 'SEND MAIL'
    }
  },
  name: 'CreateOffer',
  methods: {
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('image', file)
      this.$http
        .post('email-media/upload', formData)
        .then((response) => {
          let url = response.body // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        }, response => {
        })
    },
    sendEmailToPicklrs () {
      this.submittingForm = true
      this.submitButtonText = 'SENDING EMAIL'
      let payload = {
        subject: this.subject,
        content: this.content,
        userIds: this.checkedUsersList,
        filters: this.picklrFilters,
        selected_all: this.selectAllPicklrs
      }
      this.$validator.validateAll('send-notification').then((result) => {
        if (result) {
          this.$store.dispatch('sendEmailToPicklrs', payload).then((response) => {
            this.$notify({ text: response.body.message, type: 'success' })
            this.$emit('emailSent', 'Notification sent successfully')
            Object.assign(this.$data, (this.$options.data).apply(this))
          }, response => this.$notify({ text: 'Can not send notification', type: 'error' }))
        } else {
          console.log('error')
          this.submittingForm = true
          this.submitButtonText = 'SEND EMAIL'
        }
      })
    }
  },

  created () {
    this.$store.dispatch('getAppSettingsKeyValuePair')
    if (this.$store.state.userData.role === 'super-admin') {
      this.brandId = this.$route.params.brandId
    } else {
      this.brandId = this.$store.state.userData.brandId
    }
  }
}
</script>
<style scoped>
p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}
</style>
