









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string

  clearBlocksUsersList () {
    this.$http
      .patch('users/reset-block-users', {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        this.$notify({ text: 'Blocked Users list has been reset? now you can send message to any user', type: 'success' })
        let clearBlockedUsersPopupCloseButton: HTMLElement = this.$refs.clearBlockedUsersPopupCloseButton as HTMLElement
        clearBlockedUsersPopupCloseButton.click()
      })
  }
}
